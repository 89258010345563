import React from "react"
import { StoryGroup } from "components"
import { getEBooksCoverUrl } from "utils/rsc-util"

const eBooks = [
  {
    title: "Tarayıcı Nasıl Çalışır?",
    url: "https://learnreactui.dev/contents/tarayicilar-nasil-calisir",
    img: getEBooksCoverUrl("014_tararacilar-nasil-calisir"),
  },
]

const LinkMap = {
  //Concept
  "Training Context": "f9fd827b0231",

  URI: "e100eda1f62c",
  "Web Server": "e100eda1f62c",
  Resources: "e100eda1f62c",
  Request: "e100eda1f62c",
  Render: "e100eda1f62c",

  UI: "e100eda1f62c",
  "Browser Engine": "e100eda1f62c",
  "Rendering Engine": "e100eda1f62c",
  Networking: "e100eda1f62c",
  "JS Interpreter": "e100eda1f62c",
  "UI Backend": "e100eda1f62c",
  "Data Persistence": "e100eda1f62c",

  "Concurrency vs Paralel": "3dedbc3997c4",
  "Multi-Core": "3dedbc3997c4",
  "Multi-tasking": "3dedbc3997c4",
  "CPU Scheduling": "3dedbc3997c4",
  Fairness: "3dedbc3997c4",
  "Policy Enforcement": "3dedbc3997c4",
  Efficiency: "3dedbc3997c4",
  "Response Time": "3dedbc3997c4",
  Turnaround: "3dedbc3997c4",

  Preemptive: "3dedbc3997c4",
  "Non-Preemptive": "3dedbc3997c4",
  "Process vs Thread": "3dedbc3997c4",
  "Single-Thread": "3dedbc3997c4",
  "Multi-Thread": "3dedbc3997c4",

  Senkron: "3dedbc3997c4",
  Asenkron: "3dedbc3997c4",

  GPU: "3dedbc3997c4",
  "AGP 8X Arayüzü": "3dedbc3997c4",
  "Bellek Denetleyicisi": "3dedbc3997c4",
  "Vertex Shader": "3dedbc3997c4",
  "Pixel Shader": "3dedbc3997c4",
  "Hyper-Z": "3dedbc3997c4",

  "Süreç Yönetimi": "3dedbc3997c4",
  "Bellek Yönetimi": "3dedbc3997c4",
  "Dosya Yönetimi": "3dedbc3997c4",
  "I/O Sistem Yönetimi": "3dedbc3997c4",
  "Networking Yönetimi": "3dedbc3997c4",
  "Protection Sistemi": "3dedbc3997c4",
  "Komut Yorumlayıcı Sistemi": "3dedbc3997c4",

  "Chrome Mimarisi": "d1c65c4ca9f9",
  "Browser Process": "d1c65c4ca9f9",
  "Renderer Process": "d1c65c4ca9f9",
  Plugin: "d1c65c4ca9f9",
  "GPU ": "d1c65c4ca9f9",
  Utility: "d1c65c4ca9f9",

  "security & sandboxing": "d1c65c4ca9f9",
  "Inter Process Communication": "d1c65c4ca9f9",
  "Site Isolation": "d1c65c4ca9f9",
  "Same Origin Policy": "d1c65c4ca9f9",
  "Meltdown/Spectre": "d1c65c4ca9f9",

  "UI Thread": "1997384989a9",
  "Network Thread": "1997384989a9",
  "Storage Thread": "1997384989a9",
  "MIME Type sniffing": "1997384989a9",
  onLoad: "1997384989a9",
  beforeunload: "1997384989a9",

  "Main Thread": "4c8d34713246",
  "Web Worker": "4c8d34713246",
  "Service Worker": "4c8d34713246",
  Compositor: "4c8d34713246",
  "Rendering Engine2": "4c8d34713246",
  "JS Engine": "4c8d34713246",

  Parsing: "4c8d34713246",
  "JS Updates": "4c8d34713246",
  Merging: "4c8d34713246",
  Layout: "4c8d34713246",
  Painting: "4c8d34713246",
  Compositing: "4c8d34713246",

  EventType: "aefe76a90a29",
  EventTarget: "aefe76a90a29",

  Stack: "1986a06b0f94",
  Heap: "1986a06b0f94",
  CallStack: "1986a06b0f94",
  WebAPIs: "1986a06b0f94",
  EventLoop: "1986a06b0f94",
  TaskQueue: "1986a06b0f94",
  DOM: "1986a06b0f94",
  ajax: "1986a06b0f94",
  setTimeout: "1986a06b0f94",

  "Abstract Syntax Tree": "e6d0b33f147",
  ByteCode: "e6d0b33f147",
  "Optimizing Compiler": "e6d0b33f147",
  "Optimized Code": "e6d0b33f147",

  Value: "e6d0b33f147",
  Writable: "e6d0b33f147",
  Enumerable: "e6d0b33f147",
  Configurable: "e6d0b33f147",
}

const stories = [
  {
    title: "Tarayıcılar Nasıl Çalışır Eğitim İçeriği",
    postId: "f9fd827b0231",
  },
  {
    title: "Chrome Nasıl Çalışır - Giriş",
    postId: "e100eda1f62c",
  },
  {
    title: "Chrome Nasıl Çalışır - Temel Bilgiler",
    postId: "3dedbc3997c4",
  },
  {
    title: "Chrome Nasıl Çalışır - Multi Process Mimari",
    postId: "d1c65c4ca9f9",
  },
  {
    title: "Chrome Nasıl Çalışır - Navigasyon",
    postId: "1997384989a9",
  },
  {
    title: "Chrome Nasıl Çalışır - Rendering",
    postId: "4c8d34713246",
  },
  {
    title: "Chrome Nasıl Çalılır - Input",
    postId: "aefe76a90a29",
  },
  {
    title: "Chrome Nasıl Çalışır - JS Event Loop",
    postId: "1986a06b0f94",
  },
  {
    title: "Chrome Nasıl Çalışır - JS Processing",
    postId: "e6d0b33f147",
  },
]

export const context = {
  stories: stories,
  linkMap: LinkMap,
  eBooks: eBooks,
  title: "Chrome Nasıl Çalışır?",
  path: "chrome-nasil-calisir",
}

const HowBrowsersWorkPage = () => {
  return (
    <StoryGroup
      title={context.title}
      eBooks={context.eBooks}
      stories={context.stories}
      linkMap={context.linkMap}
    />
  )
}

export default HowBrowsersWorkPage
